import React from 'react'
import { Redirect, Link } from 'react-router-dom';
import { Box, Container, Flex, Grid, GridItem, Image, Spacer } from "@chakra-ui/react"
import PAM from '../Assets/Images/PAM.webp'
import { HamburgerIcon } from '@chakra-ui/icons'


const Header = () => {
  return (
    <>
      {/* <Box bg="black"  >
       <Flex h="10vh" alignItems="center" p="3%" justifyContent="space-between" alignItems="stretch" >
        <Box color="white">PAM</Box>
         <Spacer /> 
        <Flex w="45%" px="10px" justifyContent="space-between">
        <Link  justifyContent  to="/"><Box  color="white"> Home</Box></Link>
        <Link to="/about"><Box  color="white">About Us</Box></Link>
         <Link to="/contact"><Box color="white">Contact Us</Box></Link>
         <Link to="/productNFeatures"><Box  color="white">Products & Features</Box></Link>
         <Link><Box color="white">More</Box></Link>
        <Link><Box  color="white">Free Demo</Box></Link>
        </Flex>

       </Flex>
     </Box> */}

      <nav style={{ backgroundColor: 'black' }} className="navbar navbar-expand-lg ">
        <div className="container-fluid">
          <Image src={PAM} boxSize="70px" w="150px" className="navbar-brand" />
          <Spacer />
          <button className="navbar-toggler " type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            {/* <span style={{color:'red'}}  className="navbar-toggler-icon text-white" /> */}
            <HamburgerIcon color="white" />
          </button>
          <div style={{ justifyContent: 'flex-end' }} className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav">
              <Link to="/"><Box className="nav-link" color color="white"> Home</Box></Link>
              <Link to="/about"><Box className="nav-link" color="white">About Us</Box></Link>
              <Link to="/contact"><Box className="nav-link" color="white">Contact Us</Box></Link>
              <Link to="/productNFeatures"><Box className="nav-link" color="white">Products & Features</Box></Link>
              <Box className="nav-link">
              <li className="nav-item dropdown">
        <a style={{color:'white'}} className=" dropdown-toggle" href="#" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          More
        </a>
        <ul style={{top:'40px',left:'-75px'}} className="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
          <li><Link to="/termsNCondition" className="dropdown-item" href="#">Terms & Conditions</Link></li>
          <li><Link to="/privacyPolicy" className="dropdown-item" href="#">Privacy Policy</Link></li>
          <li><Link to="/refundNCancellation" className="dropdown-item" href="#">Refunds & Cancellations</Link></li>
        </ul>
      </li>
      </Box>
              <Link to="/"><Box className="nav-link" color color="white">Free Demo</Box></Link>
            </div>
          </div>
        </div>
      </nav>
      <hr style={{ height: '2px', colo: 'white' }} />

    </>
  )
}

export default Header
