import React from 'react'
import Header from '../Container/Header'
import { Container, Flex, Spacer, Box,Text } from '@chakra-ui/layout'
const RefundNCancellation = () => {
  return (
    <>
    <Header/>
    <Container maxWidth="110ch">
    <div className="display-1 text-center mb-5">Refund & Cancellations</div>
    <Box >
    <div className="display-4">Refund Policy</div>
    <Text >
    We support the following payment options at thepamapp.com
​   </Text>
<ul>
  <li><Text>Credit Card</Text></li>
  <li><Text>Debit Card</Text></li>
  <li><Text>UPI</Text></li>
  </ul>
  <Text >
  There are no hidden charges. You pay only the amount that you see in your order summary at the time of check out. If you see any charges on your credit/debit card for purchases made but you have never created an account or signed up, please check with your family members or business colleagues authorized to make purchases on your behalf. If you’re still unable to recognise the charge, please report the unauthorized purchase within 30 days of the transaction so that we can look into it. Please email us at support@thepamapp.com

In case of a payment failure, please retry ensuring: Information passed on to payment gateway is accurate i.e. account details, billing address, password (for net banking), etc. Kindly also check if your Internet connection has been disrupted in the process.

If your account has been debited after a payment failure, it is normally rolled back to your account within 7 business days. You can email us on support@thepamapp.com with your order number for any clarification.
​   </Text>
</Box>
<Box my="10">
    <div className="display-4">Cancellation policy</div>
    <Text >
    We accept all cancellations made before the cut-off time of our shipping slot (3 hours). Please reach out to our customer services for cancellation requests and refunds. We refund all payments made for orders placed as a store credit, which can be used by customers for future purchases. If we suspect any fraudulent transaction or transactions violating the terms of website use, we are at sole discretion to cancel such orders. These customers/accounts will be denied access from the use of site or purchase of products in the future.​   </Text>

</Box>
<Box my="10">
    <div className="display-4"> Shipping policy

</div>
    <Text >
    We are providing digital ordering of in store dine in / Takeaway services. Hence Shipping is not applicable and return of replacement of food as per the policy of respective stores from where customer has ordered.

​   </Text>

</Box>
    </Container>
    </>
  )
}

export default RefundNCancellation
