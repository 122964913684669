import React from 'react'
import Header from '../Container/Header'
import { Container, Flex, Spacer, Text } from '@chakra-ui/layout'

const PrivacyPolicy = () => {
  return (
    <>
    <Header/>
    <Container maxWidth="110ch">

    <div data-mesh-id="comp-kpzlam62inlineContent" data-testid="inline-content" className><div data-mesh-id="comp-kpzlam62inlineContent-gridContainer" data-testid="mesh-container-content"><div id="comp-kpzlam671" className="_2bafp" data-testid="richTextElement"><h1 className="font_0 my-5" style={{fontSize: '40px', textAlign: 'center'}}><span style={{fontSize: '40px'}}><span className="color_15"><span className="" style={{textDecoration: 'underline'}}>Privacy Policy</span></span></span></h1></div><div id="comp-kq0xfku6" className="_2bafp" data-testid="richTextElement"><p className="font_8" style={{fontSize: '15px'}}>Please read this privacy policy carefully by accessing or using the website/mobile application. You agree to be bound by the terms described herein. If you do not agree to all of these terms, please do not use the website/mobile application. Using the website/mobile application will be considered as a deemed acceptance of this policy.</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>Content &amp; Purpose</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>This privacy policy (“Privacy Policy”) applies to your use of the domain name <a style={{color:'teal'}} data-auto-recognition="true" href="http://www.thepamapp.com" target="_blank">www.thepamapp.com</a>, an internet based portal, and our mobile application, owned/operated by Grene Robotics Private Limited, a company duly incorporated under the provisions of the Indian Companies Act (hereinafter, referred to as “PAM” or “We” or “Our” or “Us” or “Company”). The domain name and the mobile application are collectively referred to as “Website”.</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>The Website is a digital platform that inter-alia enables the enterprises to connect to its customers by way of integration of social media platforms, in store ordering &amp; billing system and bank/payment gateways (hereinafter referred to as “Services”). The Services would be made available to such persons who have agreed to become buyers on the Website after obtaining due registration/accepting necessary terms and conditions, in accordance with the procedure as determined by us, from time to time (referred to as “You” or “Your” or “Yourself” or “User”, which terms shall also include persons who are accessing the Website merely as visitors).</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>We have implemented reasonable security practices and procedures that are commensurate with the information assets being protected and with the nature of our business. While we try our best to provide security that is commensurate with the industry standards, because of the inherent vulnerabilities of the internet, we cannot ensure or warrant complete security of all information that is being transmitted to us by you.</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>For the purpose of providing the Services and for other purposes identified in this Privacy Policy, we will be required to collect and host certain data and information of the Users. We are committed to protecting the Personal Information (as defined below) of the Users and take all reasonable precautions for maintaining confidentiality of the User’s Personal Information. This Privacy Policy has been designed and developed to help you understand the following:</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <ol className="font_8" style={{fontSize: '15px'}}>
              <li>
                <p className="font_8" style={{fontSize: '15px'}}>The type of Personal Information (including Sensitive Personal Data or Information) that we collect from the Users;</p>
              </li>
              <li>
                <p className="font_8" style={{fontSize: '15px'}}>The purpose of collection, means and modes of usage of such Personal Information by the Company;</p>
              </li>
              <li>
                <p className="font_8" style={{fontSize: '15px'}}>How and to whom the Company will disclose such information;</p>
              </li>
              <li>
                <p className="font_8" style={{fontSize: '15px'}}>How the Company will protect the Personal Information including Sensitive Personal Data or Information that is collected from the Users; and</p>
              </li>
              <li>
                <p className="font_8" style={{fontSize: '15px'}}>How Users may access and/or modify their Personal Information.</p>
              </li>
            </ol>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>This Privacy Policy shall apply to the use of the Website by all Users. Accordingly, a condition of each User's use of and access to the Website and to the other services provided by the Company to Users is their acceptance of this Privacy policy. Any User is required to read and understand the provisions set out herein prior to submitting any Sensitive Personal Data or Information to the Company, failing which they are required to leave the Website immediately.</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>This Privacy Policy is published in compliance of the (Indian) Information Technology Act, 2000 and the rules, regulations, guidelines and clarifications framed thereunder, including the (Indian) Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011.&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>Personal Information</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>“Personal Information” means any information that relates to a natural person, which, either directly or indirectly, in combination with other information available with the Company, is capable of identifying the person concerned.</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>“Sensitive Personal Data or Information” means Personal Information of any individual relating to password; financial information such as bank account or credit card or debit card or other payment instrument details; physical, physiological and mental health condition; sexual orientation; medical records and history; biometric information; any detail relating to the above as provided to or received by the Company for processing or storage. However, any data/information relating to an individual that is freely available or accessible in public domain or furnished under the Right to Information Act, 2005 or any other law shall not qualify as Sensitive Personal Data or Information.</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>Types of Personal Information collected by the Company</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>A User may have limited access to the Website and utilize some of the Services provided by the Company without creating an account on the Website. Unregistered Users can access some of the information and details available on the Website. In order to have access to all the features and benefits on our Website, a User may be required to first create an account on our Website. As part of the registration process, we may collect the following categories of Personal Information from the Users (hereinafter collectively referred to as “User Information”):</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <ul className="font_8" style={{fontSize: '15px'}}>
              <li>
                <p className="font_8" style={{fontSize: '15px'}}>Name;</p>
              </li>
              <li>
                <p className="font_8" style={{fontSize: '15px'}}>User ID;</p>
              </li>
              <li>
                <p className="font_8" style={{fontSize: '15px'}}>Email address;</p>
              </li>
              <li>
                <p className="font_8" style={{fontSize: '15px'}}>Address (including country and ZIP/postal code);</p>
              </li>
              <li>
                <p className="font_8" style={{fontSize: '15px'}}>Gender;</p>
              </li>
              <li>
                <p className="font_8" style={{fontSize: '15px'}}>Age;</p>
              </li>
              <li>
                <p className="font_8" style={{fontSize: '15px'}}>Phone Number;</p>
              </li>
              <li>
                <p className="font_8" style={{fontSize: '15px'}}>Password chosen by the User;</p>
              </li>
              <li>
                <p className="font_8" style={{fontSize: '15px'}}>Valid financial account information; and</p>
              </li>
              <li>
                <p className="font_8" style={{fontSize: '15px'}}>Other details as the User may volunteer.</p>
              </li>
            </ul>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>In order to avail the Services, the Users may be required to upload/share certain documents, on the Website and/or e-mail the same to the Company in accordance with the Website’s terms of use (“Terms of Use”) and such documents will be stored/disclosed by the Company only in the manner specified in this Privacy Policy and the Terms of Use. The term “User Information” shall also include any such documents uploaded or otherwise provided by Users. We may keep records of telephone calls received and made for making inquiries, orders, or other purposes for the purpose of administration of Services.</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>Internet Use</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>We may also receive and/or hold information about the User’s browsing history including the URL of the site that the User visited prior to visiting the website as well as the Internet Protocol (IP) address of each User's computer (or the proxy server a User used to access the World Wide Web), User's computer operating system and type of web browser the User is using as well as the name of User's ISP. The Website uses temporary cookies to store certain data (that is not Sensitive Personal Data or Information) that is used by us for the technical administration of the Website, research and development, and for User administration.</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <ul className="font_8" style={{fontSize: '15px'}}>
              <li>
                <p className="font_8" style={{fontSize: '15px'}}>We do not knowingly collect Personal Information from children.</p>
              </li>
              <li>
                <p className="font_8" style={{fontSize: '15px'}}>We may in future include other optional requests for information from the User including through user surveys in order to help us customize the Website to deliver personalized information to the User and for other purposes are mentioned herein. Such information may also be collected in the course of contests conducted by us. Any such additional Personal Information will also be processed in accordance with this Privacy Policy.</p>
              </li>
            </ul>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>Purposes for which the Company may use the Information</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>We will retain User Information only to the extent it is necessary to provide Services to the Users. The information, which we collect from you, may be utilized for various business and/or regulatory purposes including for the following purposes:</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <ol className="font_8" style={{fontSize: '15px'}}>
              <li>
                <p className="font_8" style={{fontSize: '15px'}}>Registration of the User on the Website;</p>
              </li>
              <li>
                <p className="font_8" style={{fontSize: '15px'}}>Processing the User’s orders/requests and provision of Services (including provision of safe Services);</p>
              </li>
              <li>
                <p className="font_8" style={{fontSize: '15px'}}>Completing transactions with Users effectively and billing for the products/Services provided;</p>
              </li>
              <li>
                <p className="font_8" style={{fontSize: '15px'}}>Technical administration and customization of Website;</p>
              </li>
              <li>
                <p className="font_8" style={{fontSize: '15px'}}>Ensuring that the Website content is presented to the Users in an effective manner;</p>
              </li>
              <li>
                <p className="font_8" style={{fontSize: '15px'}}>Delivery of personalized information and target advertisements to the User;</p>
              </li>
              <li>
                <p className="font_8" style={{fontSize: '15px'}}>Improvement of Services, features and functionality of the Website;</p>
              </li>
              <li>
                <p className="font_8" style={{fontSize: '15px'}}>Research and development and for User administration (including conducting user surveys);</p>
              </li>
              <li>
                <p className="font_8" style={{fontSize: '15px'}}>Non-personally identifiable information, exclusively owned by the Company may be used in an aggregated or non-personally identifiable form for internal research, statistical analysis and business intelligence purposes including those for the purposes of determining the number of visitors and transactional details, and we may sell or otherwise transfer such research, statistical or intelligence data in an aggregated or non-personally identifiable form to third parties and affiliates;</p>
              </li>
              <li>
                <p className="font_8" style={{fontSize: '15px'}}>Dealing with requests, enquiries, complaints or disputes and other customer care related activities including those arising out of the Users’ request of the Services and all other general administrative and business purposes;</p>
              </li>
              <li>
                <p className="font_8" style={{fontSize: '15px'}}>Communicate any changes in our Services or this Privacy Policy or the Terms of Use to the Users;</p>
              </li>
              <li>
                <p className="font_8" style={{fontSize: '15px'}}>Verification of identity of Users and perform checks to prevent frauds; and</p>
              </li>
              <li>
                <p className="font_8" style={{fontSize: '15px'}}>Investigating, enforcing, resolving disputes and applying our Terms of Use and Privacy Policy, either ourselves or through third party service providers.&nbsp;</p>
              </li>
            </ol>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>Disclosure and Transfer of User’s Personal Information</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>We may need to disclose/transfer User’s Personal Information to the following third parties for the purposes mentioned in this Privacy Policy and the Terms of Use:</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>Government institutions/authorities to the extent required:</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <ol className="font_8" style={{fontSize: '15px'}}>
              <li>
                <p className="font_8" style={{fontSize: '15px'}}>Under the laws, rules, and regulations and/or under orders of any relevant judicial or quasi-judicial authority;</p>
              </li>
              <li>
                <p className="font_8" style={{fontSize: '15px'}}>To protect and defend the rights or property of the Company;</p>
              </li>
              <li>
                <p className="font_8" style={{fontSize: '15px'}}>To fight fraud and credit risk;</p>
              </li>
              <li>
                <p className="font_8" style={{fontSize: '15px'}}>To enforce the Company's Terms of Use (to which this Privacy Policy is also a part); or</p>
              </li>
              <li>
                <p className="font_8" style={{fontSize: '15px'}}>When the Company, in its sole discretion, deems it necessary in order to protect its rights or the rights of others.</p>
              </li>
            </ol>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>If otherwise required by an order under any law for the time being in force including in response to enquiries by Government agencies for the purpose of verification of identity, or for prevention, detection, investigation including cyber incidents, prosecution, and punishment of offences.</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>The Company make all User Information accessible to its employees and data processors only on a need-to-know basis. All the employees and data processors, who have access to, and are associated with the processing of User Information, are obliged to respect its confidentiality.</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>Non-personally identifiable information may be disclosed to third party ad servers, ad agencies, technology vendors and research firms to serve advertisements to the Users. The Company may also share its aggregate findings (not specific information) based on information relating to the User’s internet use to prospective, investors, strategic partners, sponsors and others in order to help growth of Company's business.</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>We may also disclose or transfer the User Information, to another third party as part of reorganization or a sale of the assets or business of Company. Any third party to which the Company transfers or sells its assets will have the right to continue to use the Personal Information and/or other information that a User provide to us.</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>Links to Third-Party</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>The links to third-party advertisements, third party websites or any third party electronic communication services (referred to as “Third Party Links”) may be provided on the Website which are operated by third parties and are not controlled by, or affiliated to, or associated with the Company unless expressly specified on the Website.</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>If you access any such Third Party Links, we request you review the website’s privacy policy. We are not responsible for the policies or practices of Third Party Links.&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>Security Practices and Procedures</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>The Company adopts reasonable security practices and procedures to include, technical, operational, managerial and physical security control measures in order to protect the Personal Information in its possession from loss, misuse and unauthorized access, disclosure, alteration and destruction.</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>The Company takes adequate steps to ensure that third parties to whom the Users’ Sensitive Personal Data or Information may be transferred adopt reasonable level of security practices and procedures to ensure security of Personal Information.</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>You hereby acknowledge that the Company is not responsible for any intercepted information sent via the internet, and you hereby release us from any and all claims arising out of or related to the use of intercepted information in any unauthorized manner.</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>User's rights in relation to their personal information collected by the Company</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>All the information provided to the Company by a User, including Sensitive Personal Data or Information, is voluntary. User has the right to withdraw his/her/its consent at any time, in accordance with the terms of this Privacy Policy and the Terms of Use, but please note that withdrawal of consent will not be retroactive.</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>Users can access, modify, correct and delete the Personal Information provided by them which has been voluntarily given by the User and collected by the Company in accordance with this Privacy Policy and Terms of Use. However, if the User updates his/her information, the Company may keep a copy of the information which User originally provided to the Company in its archives for User documented herein. In case the User seeks to update or correct, his/her Personal Information, the User may exercise these rights by emailing the Company at &nbsp;
            <a style={{color:'teal'}} data-auto-recognition="true" href="mailto:contact@thepamapp.com">contact@thepamapp.com</a> and communicate the change(s) for updating the Company’s records.</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>In case the User does not provide his/her information or consent for usage of Personal Information or subsequently withdraws his/her consent for usage of the Personal Information so collected, the Company reserves the right to discontinue the services for which the said information was sought.&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>Complaints and Grievance Redressal</p>
            <p className="font_8" style={{fontSize: '15px'}}>&nbsp;</p>
            <p className="font_8" style={{fontSize: '15px'}}>Any complaints, abuse or concerns with regards to content and or comment or breach of these terms shall be immediately informed to the designated Grievance department as mentioned below via in writing or through email signed with the electronic signature.</p>
            <p className="font_8" style={{fontSize: '15px'}}>​</p>
            <p className="font_8" style={{fontSize: '15px'}}>​</p>
            <p className="font_8" style={{fontSize: '15px'}}>Gaurav Sharma</p>
            <p className="font_8" style={{fontSize: '15px'}}>Plot No. 437, Road No. 20 Jubilee Hills,</p>
            <p className="font_8" style={{fontSize: '15px'}}>Telangana – 500033,</p>
            <p className="font_8" style={{fontSize: '15px'}}>Hyderabad,</p>
            <p className="font_8" style={{fontSize: '15px'}}>India.</p>
            <p className="font_8" style={{fontSize: '15px'}}>​</p>
            <p className="font_8" style={{fontSize: '15px'}}>Email ID: <object height={0}><a data-auto-recognition="true" href="mailto:legals@thepamapp.com">legals@thepamapp.com</a></object></p>
            <p className="font_8" style={{fontSize: '15px'}}><span className="wixGuard">​</span></p>
            <p className="font_8" style={{fontSize: '15px'}}><span className="wixGuard">​</span></p>
            <p className="font_8" style={{fontSize: '15px'}}><span className="wixGuard">​</span></p></div></div></div>
            </Container>
    </>
  )
}

export default PrivacyPolicy
