import React from 'react'
import { Box, Container, Image,Flex, Grid, GridItem,Textarea, Spacer, Text, Input } from "@chakra-ui/react"
import Background from '../Assets/Images/Background.jpg'
import healthy from '../Assets/Images/healthy.jpg'

import Ipad from '../Assets/Images/Ipad.webp'
import Header from '../Container/Header'

const Home = () => {
  return (
    <>
    <Header/>
    <Box h="100%" 
     background={`linear-gradient(148deg, rgba(0, 0, 0, 77%), rgba(251, 247, 247, 0%)), url(${healthy})`}
      backgroundPosition="center"
      backgroundColor="black"  
      backgroundSize="cover"
      backgroundRepeat="no-repeat">

      <div className="">
        <Flex  justifyContent="center" alignItems="center"><Text fontFamily="monospace" m="auto" fontSize="45px" textAlign="center" color="white"  >GET UNIFIED DIGITAL TRANSFORMATION FOR FREE
       </Text></Flex>
      </div>
      <div className="row">
      <div className="col-md-1">
        </div>
        <div className="col-md-3 col-sm-12 p-3">
          <Flex h="45vh" backgroundColor="black"  direction="column">
            <Text fontSize="30px" color="white" ml="auto" mr="auto" mt="10">Welcome</Text>
            <Text fontSize="25px" color="white" ml="auto" mr="auto" textAlign="center">I'm PAM, Your Virtual Hostess!</Text>

            <Text fontSize="25px" color="red" textAlign="center" ml="auto" mr="auto" mt="40px">I can help grow your restaurant's business by 20%</Text>

            <Text fontSize="25px" color="white" ml="auto" mr="auto" mt="40px">GO BIG . GO DIGITAL
           </Text>

          </Flex>
        </div>
        <div className="col-md-3">
        </div>
        <div className="col-md-3 col-sm-12 p-3" >
          <Box p="6" border="4px" backgroundColor="white" borderColor="gray.200">
        <form>
          <Flex h="60vh" flexDirection="column" justifyContent="space-evenly">
          <div className="row ">
            <div className="col-6">
          <Input placeholder="Name" type="name" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
          </div>
          <div className="col-6">
          <Input placeholder="Phone"  type="number" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
          </div>
          </div>
           <div className="row">
             <div className="col-md-12">
          <Input placeholder="Email address"  type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
          <div id="emailHelp" style={{fontSize:'10px'}} className="form-text">We'll never share your email with anyone else.</div>
          </div>
          </div>
          <div className="row">
            <div className="col-md-12">
          <Input placeholder="Business Name"  type="name" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
          </div>
         
          </div>
          <div className="row">
          <div className="col-md-12">
          <Input placeholder="Business Location"  type="name" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
          </div>
          </div>
          <div className="row">
        <div className="col-md-12">
          <Textarea placeholder="Any additional information you would like to share ?" />
        </div>
        </div>
       
        <button type="submit" className="btn btn-primary">Submit</button>
        </Flex>
      </form>
      </Box>
        </div>
        <div className="col-md-2">
        </div>
      </div>
    <Box height="100%" pb="20">
      <Text fontSize="50px" mb="20" color="white" textAlign="center">
      Sell Manage Engage COMMISSION FREE !
      </Text>
    </Box>
    </Box>
    </>
  )
}

export default Home
