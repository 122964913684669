import { Image } from '@chakra-ui/image'
import { Container, Flex, Spacer, Text } from '@chakra-ui/layout'
import React from 'react'
import Header from '../Container/Header'
import features from '../Assets/Images/features.webp'

const ProductNFeatures = () => {
  return (
    <>
      <Header />
      <Flex direction="column" m="auto" justify="center" alignItems="center" >
        <div className="display-1 mb-3 mt-5">
          Products & Features
          </div>
        <Container maxWidth="90ch">
          <Image src={features} />
        </Container>

        <Container maxWidth="90ch" marginTop="40px">
          <Flex justifyContent="space-between">
            <Flex flexDirection="column">
              <Flex id="op-1" direction="column" mb="40px" w="100%">
                <Flex id="op-2" w="100%">
                  <Flex id="op-3" direction="column" w="130%">
                    <Text fontSize="25px" >Discovery</Text>
                    <Text fontSize="14px" color="#ffc107" fontWeight="extrabold" >Online food ordering system</Text>
                    <Text> -Web App</Text>
                    <Text>-Mobile App</Text>
                  </Flex>
                  <Spacer />
                  <Text w="100%" alignSelf="flex-start">
                    Your Restaurants own contactless online food ordering system
                </Text>
                </Flex>
              </Flex>

              <Flex id="op-1" direction="column" mb="40px" w="100%">
                <Flex id="op-2" w="100%">
                  <Flex id="op-3" direction="column" w="130%">
                    <Text fontSize="25px" >Operations</Text>
                    <Text fontSize="14px" color="#ffc107" fontWeight="extrabold" >Business Management System</Text>
                    <Text> -HR/Staff Management</Text>
                    <Text>-Restaurant Guest Management</Text>
                    <Text> -Table Management</Text>
                    <Text>-Inventory Management </Text>
                    <Text>-Third Party Order Management</Text>
                    <Text>-Kitchen Management</Text>
                    <Text>-Valet Management</Text>
                  </Flex>
                  <Spacer />
                  <Text w="100%">
                  A complete POS solution that is cloud based to manage your daily operations.
                </Text>
                </Flex>
              </Flex>

              <Flex id="op-1" direction="column" mb="40px" w="100%">
                <Flex id="op-2" w="100%">
                  <Flex id="op-3" direction="column" w="130%">
                    <Text fontSize="25px" >Engagement</Text>
                    <Text fontSize="14px" color="#ffc107" fontWeight="extrabold" >CRM Solution</Text>
                    <Text> - Loyalty Programme</Text>
                    <Text>- Feedback System</Text>

                  </Flex>
                  <Spacer />
                  <Text w="100%" alignSelf="flex-start">
                    Engage your customers with automated marketing campaigns

                </Text>
                </Flex>
              </Flex>

              <Flex id="op-1" direction="column" mb="40px" w="100%">
                <Flex id="op-2" w="100%">
                  <Flex id="op-3" direction="column" w="130%">
                    <Text fontSize="25px" >Enterprise</Text>
                    <Text fontSize="14px" color="#ffc107" fontWeight="extrabold" >Enterprise Management</Text>

                  </Flex>
                  <Spacer />
                  <Text w="100%" alignSelf="flex-end">
                    Take charge of all the outlets of your restaurant. Set up your
                    enterprise’s presence online. LimeTray’s complete suite of
                    enterprise software solutions will help you organise your
                    operations, marketing and management functions with ease.
                </Text>
                </Flex>
              </Flex>
            </Flex>
            <Spacer />

          </Flex>
        </Container>

      </Flex>
    </>
  )
}

export default ProductNFeatures
