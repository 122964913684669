import { Button } from '@chakra-ui/button'
import { Flex, Text, Container, Box } from '@chakra-ui/layout'

import React from 'react'
import Header from '../Container/Header'
import './Components.css'
import contact from '../Assets/Images/contact.jpg'

const Contact = () => {
  return (
  <>
  <Header/>
  <Box background={`linear-gradient(148deg, rgba(0, 0, 0, 77%), rgba(251, 247, 247, 0%)), url(${contact})`}
        backgroundPosition="center"
        backgroundColor="black"
        backgroundSize="cover"
        backgroundRepeat="no-repeat" direction="column" justify="center" alignItems="center" >
  <div className="background">
        <div className="container">
          <div className="screen">
            <div className="screen-header">
              <div className="screen-header-left">
                <div className="screen-header-button close" />
                <div className="screen-header-button maximize" />
                <div className="screen-header-button minimize" />
              </div>
              <div className="screen-header-right">
                <div className="screen-header-ellipsis" />
                <div className="screen-header-ellipsis" />
                <div className="screen-header-ellipsis" />
              </div>
            </div>
            <div className="screen-body">
              <div className="screen-body-item left">
                <div className="app-title">
                  <span>CONTACT</span>
                  <span>US</span>
                </div>
                <div className="app-contact">
                <div style={{fontSize:'12px',color:'white'}} >CONTACT INFO : </div>
                <div style={{fontSize:'12px',color:'white'}} > sales@greneos.com</div>
                <div style={{fontSize:'12px',color:'white'}} >+919052890099 </div>
              </div>
              </div>
              <div className="screen-body-item">
                <div className="app-form">
                  <div className="app-form-group">
                    <input className="app-form-control" placeholder="NAME"  />
                  </div>
                  <div className="app-form-group">
                    <input className="app-form-control" placeholder="EMAIL" />
                  </div>
                  <div className="app-form-group">
                    <input className="app-form-control" placeholder="CONTACT NO" />
                  </div>
                  <div className="app-form-group message">
                    <input className="app-form-control" placeholder="MESSAGE" />
                  </div>
                  <div className="app-form-group buttons">
                    <Button color="#ea1d6f" mr="2" variant="ghost">CANCEL</Button>
                    <Button color="#ea1d6f" ml="2" variant="ghost">SEND</Button>

                    {/* <button className="app-form-button mr-5 text-center pr-5">CANCEL</button>
                    <button className="app-form-button">SEND</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
      </Box>
  </>
  )
}

export default Contact
