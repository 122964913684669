import React from 'react'
import Header from '../Container/Header'
import { Flex, Text, Container, Box } from '@chakra-ui/layout'
import healthy from '../Assets/Images/Background.jpg'


const About = () => {
  return (
    <>
      <Header />
      <Flex pt="80px" h="100%" pb="20px" background={`linear-gradient(148deg, rgba(0, 0, 0, 77%), rgba(251, 247, 247, 0%)), url(${healthy})`}
        backgroundPosition="center"
        backgroundColor="black"
        backgroundSize="cover"
        backgroundRepeat="no-repeat" direction="column" justify="center" alignItems="center" >
        <Container m="auto" maxWidth="110ch">
          <div className="display-1 text-center text-white">About Us</div>
          <Text textAlign="center" color="white" fontSize="20px">Finding Inspiration in Every Turn</Text>
          <Text mt="10" mb="10" color="white" textAlign="center" fontSize="25px">PAM, your virtual hostess.. was born amidst the pandemic as a zero touch unified sales acceleration platform that helps transform your hospitality service business. It helps optimise your business by 20% growth in sale with its rich integrated ecosystem.</Text>
          <Text textAlign="center" color="white">
            Our mission at PAM is to make technology affordable and accessible for everyone. The platform and all the products are designed particularly to enable even the least technically inclined entrepreneurs to exploit the most advanced technology with ease.

            As restaurant owners ourselves the underlying idea was to ensure that every business in this competitive environment has a fighting chance to grow and flourish. Every business in all shapes and sizes gets all the support they need on our platform and can choose exactly the solution that fits their unique need.


        </Text>
        </Container>
        <Container maxWidth="101ch">
        <div className="conatiner">
          <Box h="100%"
            bg="#6c757d"
            fontWeight="extrabold"
            backgroundPosition="center"
            backgroundSize="cover"
            backgroundRepeat="no-repeat" >
            <p className="display-2 p-5 m-2 text-center text-white">   
              SELL . MANAGE . ENGAGE
              COMMISSION FREE
             </p>


          </Box>
        </div>
        </Container>
      </Flex>


    </>
  )
}

export default About
