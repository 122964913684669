import logo from './logo.svg';
import './App.css';
import Header from './Container/Header';
import Home from './Components/Home';
import About from './Components/About';
import Contact from './Components/Contact';
import PrivacyPolicy from './Components/PrivacyPolicy';
import ProductNFeatures from './Components/ProductNFeatures';
import RefundNCancellation from './Components/RefundNCancellation';
import TermsNCondition from './Components/TermsNCondition';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
function App() {
  return (
    <>
      <Router>
      <Route path={'/'} exact component={Home} />
      <Route path={'/about'} exact component={About} />
      <Route path={'/contact'} exact component={Contact} />
      <Route path={'/privacyPolicy'} exact component={PrivacyPolicy} />
      <Route path={'/productNFeatures'} exact component={ProductNFeatures} />
      <Route path={'/refundNCancellation'} exact component={RefundNCancellation} />
      <Route path={'/termsNCondition'} exact component={TermsNCondition} />


  {/* <Header/>
  <Home/> */}
  </Router>
  </>
  );
}

export default App;
